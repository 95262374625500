@use '@angular/material' as mat;
@import "apps/flow/src/styles/vars.scss";

shared-ui-flow-process-list,
shared-ui-flow-task-list,
{
  text-align: left;

  mat-progress-bar.mat-progress-bar {
    margin-top: -2px;
    height: 2px;
  }

  .flow-list-item {
    border-radius: 4px;
    background-color: white;
    margin-bottom: 2px;
    padding: 8px 16px;
  }

  .flow-list-item.header {
    & .selected-filter {
      display: flex;
      align-items: center;
      border-radius: 4px;
      gap: 4px;
      font-size: 12px;
      color: white;
      background-color: #3f51b5;
      padding: 0 4px;

      .filter {
        flex: 1 1 auto;
      }

      .mat-icon {
        cursor: pointer;
        opacity: 0.5;
        height: 16px;
        width: 16px;
        font-size: 16px;
      }

      .mat-icon:hover {
        opacity: 1;
      }
    }
  }

  .flow-list-item:not(.header) {
    position: relative;
    transition: background-color 0.25s linear;

    .flow-list-item-primary-action::after {
      position: absolute;
      inset: 0;
      content: "";
    }

    a:not(.flow-list-item-primary-action), button:not(.flow-list-item-primary-action) {
      width: fit-content;
      position: relative;
      z-index: 2;
    }
  }

  .flow-list-item:not(.header) {
    cursor: pointer;
    text-decoration: none;
    color: black;

    &:focus-within {
      outline: 2px solid #92e072;
      outline-offset: -2px;
    }

    .flow-list-item-primary-action {
      mat-icon.mat-icon {
        width: 38px;
        height: 38px;
        font-size: 38px;
        color: #3f51b5;
        margin-left: -12px;
        opacity: 0;
        transition: opacity 0.25s linear, margin-left 0.25s linear;
      }

      &:focus, &:hover, &:active {
        mat-icon.mat-icon {
          opacity: 1;
          margin-left: 0;
        }
      }
    }
  }
}

@media ($media-breakpoint-max-sm) {
  shared-ui-flow-document-list {

    .flow-list-item.header {
      display: none;
    }

    .flow-list-item {
      grid-template-columns: 48px 100%;

      *:nth-child(3) {
        grid-row-start: 2;
        grid-column-start: 2;
      }

      *:nth-child(4) {
        grid-row-start: 3;
        grid-column-start: 2;
      }

      & .details {
        display: none;
      }
    }
  }
}
