@use '@angular/material' as mat;
@import "../boku-theme";
@import "../vars";

button {
  border-radius: 4px;

  &.mat-button-base {
    transition: background-color 0.5s;

    shared-ui-flow-icon:first-child {
      margin-right: 8px;
    }

    shared-ui-flow-icon:last-child {
      margin-left: 8px;
    }

    &.mat-button-disabled {
      cursor: not-allowed;
    }
  }

  &.mat-button-base.mat-icon-button {
    transition: background-color 0.5s;

    &:hover {
      background-color: $grey;
    }

    &.mat-primary {
      background-color: $grey_lighter;

      &:hover {
        background-color: $grey;
      }
    }
  }

  &.mat-button-base.mat-flat-button {
    color: white;
    background-color: $grey_lighter;

    &:hover {
      background-color: $grey_light;
    }

    &.mat-primary {
      background-color: #737373;
      &:not(.mat-button-disabled):hover {
        background-color: #606060;
      }
    }

    &.mat-accent {
      background-color: $accent-color-light;
      color: #000000;
      &:not(.mat-button-disabled):hover {
        background-color: $accent-color-dark;
      }
    }

    &.mat-warn {
      background-color: $warn-color-light;
      color: #000000;
      &:not(.mat-button-disabled):hover {
        background-color: $warn-color-dark;
      }
    }

    &.mat-button-disabled {
      background-color: $grey;
    }

    &.outcome-green {
      background-color: mat.get-color-from-palette(mat.define-palette(mat.$green-palette), 700);
      &:not(.mat-button-disabled):hover {
        background-color: mat.get-color-from-palette(mat.define-palette(mat.$green-palette), 800);
      }
    }

    &.outcome-red {
      background-color: mat.get-color-from-palette(mat.define-palette(mat.$red-palette), 700);
      &:not(.mat-button-disabled):hover {
        background-color: mat.get-color-from-palette(mat.define-palette(mat.$red-palette), 800);
      }
    }

    &.outcome-default {
      background-color: #464646;
    }
  }


}

mat-button-toggle-group {

  mat-button-toggle.mat-button-toggle-checked {
    &.mat-button-toggle-appearance-standard {
      color: white;
      background-color: #464646;
    }

    .icon {
      filter: invert(1) !important;
    }
  }
}
