@use '@angular/material' as mat;

@include mat.core();
$config: mat.define-typography-config();

$primary: mat.define-palette(mat.$green-palette);
$primary-color: mat.get-color-from-palette($primary, 400);
$primary-color-light: mat.get-color-from-palette($primary, 200);
$primary-color-dark: mat.get-color-from-palette($primary, 800);

$accent: mat.define-palette(mat.$orange-palette);
$accent-color: mat.get-color-from-palette($accent, 400);
$accent-color-light: mat.get-color-from-palette($accent, 200);
$accent-color-dark: mat.get-color-from-palette($accent, 800);

$warn: mat.define-palette(mat.$red-palette);
$warn-color: mat.get-color-from-palette($warn, 400);
$warn-color-light: mat.get-color-from-palette($warn, 300);
$warn-color-dark: mat.get-color-from-palette($warn, 800);
