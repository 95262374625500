@use '@angular/material' as mat;
@import "../vars";
@import "../boku-theme";

.eben-widget.readonly {
  display: flex;
  margin-bottom: 8px;

  .readonly-name {
    flex: 0 0 180px;
    text-align: right;
    margin-right: 16px;
    //border-bottom: 1px solid $grey_lighter;
  }

  @media ($media-breakpoint-max-md) {
    flex-wrap: wrap;

    .readonly-name {
      flex: 1 1 100%;
      text-align: left;
    }
  }
}

.eben-widget:not(.readonly) {
  margin-bottom: 16px;

  .label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-weight: 400;

    mat-icon {
      color: $grey;
      cursor: pointer;
      transition: color 0.25s;
      font-size: 21px;
      width: 20px;
      height: 20px;
      padding-left: 4px;

      &:hover {
        color: $grey-darker;
      }
    }

  }


  .input {
    z-index: 1;
  }

  @media ($media-breakpoint-max-md) {
    .input {
      flex: 1 1 100%;
    }

    eben-ui-error-widget {
      flex: 1 1 100%;
      padding: 0;
      margin-top: 8px;
    }
  }

  eben-ui-error-widget {
    color: mat.get-color-from-palette($warn);
    display: none;
  }

  &.invalid {
    eben-ui-error-widget {
      display: inline-block;
    }
  }
}

eben-feature-eben-dynamic-table {
  .eben-widget.readonly {
    margin-bottom: 16px;

    .row {
      border-bottom: 1px solid $grey_lighter;
      padding-bottom: 8px;

      .column {
        display: flex;
        flex-direction: row;
        margin-bottom: 1px;

        .key {
          display: inline-block;
          content: attr(label);
          width: 100px;
          min-width: 100px;
          font-size: $font-smaller;
          color: $grey-darker;
          background-color: $grey_lighter;
          padding: 4px;
          margin-right: 8px;
        }

        .value {
          display: inline-block;
        }
      }
    }
  }

  .eben-widget:not(.readonly) {
    .row {
      border-bottom: 1px solid $grey_lighter;
    }

    .validation-icon {
      display: flex;
      align-items: center;
    }
  }

  @media ($media-breakpoint-max-md) {
    .eben-widget.readonly {
      .row {
        margin-bottom: 4px;

        .column {
          margin-bottom: 1px;

          .key {
            display: inline-block;
            width: 100px;
            min-width: 100px;
            font-size: $font-smaller;
            color: $grey-darker;
            background-color: $grey_lighter;
            padding: 4px;
            margin-right: 8px;
          }

          .value {
            display: inline-block;
          }
        }
      }
    }
  }

}

eben-feature-eben-upload {
  .eben-widget.readonly {
    display: block;

    button.document {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 4px 8px;
      background-color: white;

      & > * {
        flex: 0 0 auto;
      }

      &:hover, &:focus, &:active {
        background-color: $grey_lighter;
      }
    }
  }
}

eben-homeoffice-bv-tage {
  .eben-widget:not(.readonly) {
    max-width: 100%;

    eben-ui-error-widget {
      flex: 1 1 100%;
      display: block;
      padding: 8px;
    }
  }

  .eben-widget.readonly {
    flex-wrap: nowrap;
    flex-direction: column;
    max-width: unset;

    .homeoffice-day {
      display: flex;
      gap: 8px;
      margin-bottom: 1px;

      @media ($media-breakpoint-max-md) {
        gap: 0;
        margin-bottom: 8px;
      }

      span {
        display: inline-block;
        padding: 0 8px;

      }

      .uhrzeitVon, .uhrzeitBis {
        width: 40px;
      }

      .zeitspanne {
        background-color: $grey_lighter;
        font-weight: 500;
        text-align: right;
        flex: 0 0 48px;
      }

      .summe {
        font-weight: 500;
        background-color: $grey_lighter;
        text-align: right;
        flex: 0 0 48px;
      }
    }


    @media ($media-breakpoint-max-md) {
      .homeoffice-day {
        flex-wrap: wrap;

        .readonly-name {
          flex: 1 1 100%;
        }
      }
    }
  }
}

eben-checkbox {
  .eben-widget:not(.readonly) {
    .input {
      display: flex;
      align-items: center;

      span.mat-checkbox-label {
        white-space: pre-line;
      }
    }
  }
}

eben-dropdown {
}

eben-multi-line-text {
  .eben-widget:not(.readonly) {
    textarea {
      height: 8em !important;
    }

    & > * {
      flex: 1 1 100%;
    }

    eben-ui-error-widget {
      flex: 1 1 100%;
      padding: 0;
      margin-top: 8px;
    }
  }
}

eben-slide-toggle {
  .eben-width {

    &.slide-toggle {
      color: #757575;
      width: 180px;
      margin-right: 16px;
      text-align: right;
      font-size: 12px;
      display: inline-block;

      &::after {
        font-family: 'Material Icons';
        content: "done";
        margin-right: 8px;
        position: absolute;
        margin-left: 16px;
      }
    }

    &.focus {
      .ng-toggle-switch-core {
        color: #495057;
        background-color: #fff;
        border-color: #92e072;
        outline: 0;
        box-shadow: 0 0 4px 0.3rem rgb(77 172 38 / 25%);
      }
    }

    &.readonly {

    }
  }
}

eben-date {
  .eben-widget {
    .date-widget {
      width: 160px;
      position: relative;

      mat-datepicker-toggle {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &.disabled {
      input {
        color: $grey;
      }
    }
  }
}

eben-radio {
  .mat-radio-button {
    .mat-radio-label-content {
      white-space: break-spaces;
    }
  }
}
