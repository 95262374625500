@import '../vars';

.eben-dialog {

  width: 480px;

  &.eben-dialog-latest-task-form {
    width: fit-content;
  }

  &.eben-dialog-task-form,
  &.eben-dialog-start-form,
  &.eben-dialog-workflow-details {
    width: 1024px;
  }


  @media ($media-breakpoint-max-sm) {
    width: 100vw;
    height: 100vh;
    max-width: 100% !important;
    max-height: 100% !important;
  }
}

eben-feature-dialog-task-form,
eben-feature-dialog-start-form {
  .mat-dialog-title {
    width: 100%;
  }

  button.close {
    margin-top: -8px;
    margin-right: -8px;
  }

  mat-dialog-content.mat-dialog-content {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    position: relative;

    eben-form-stepper {
      width: 250px;
      position: sticky;
      z-index: 1;
      padding-left: 32px;
      flex: 0 0 auto;
      top: 0;
      overflow-y: auto;
    }

    .form.with-form-help {
      overflow: hidden;
    }

    .form {
      margin: 0 auto;
      flex-grow: 1;
      place-content: center;
      overflow-y: auto;

      #adf-form-renderer {
        margin-left: 88px;
        margin-right: 88px;
      }

      .left-drawer-toggle {
        position: absolute;
        left: 0;

        button {
          font-size: $font-small;
          line-height: 24px;
          width: 100px;
          position: fixed;

          transform: rotate(270deg);
          color: #757575;
          margin-left: -38px;
          margin-top: 38px;
          border-radius: 0;
          padding: 0 4px;
          transition: margin-left 0.2s ease-in-out;
          z-index: 2;
        }
      }

      .right-drawer-toggle {
        position: absolute;
        right: 0px;

        button {
          font-size: $font-small;
          line-height: 24px;
          width: 60px;
          position: fixed;
          margin-left: -44px;

          margin-top: 20px;
          transform: rotate(90deg);
          color: #757575;
          border-radius: 0;
          padding: 0 4px;
          z-index: 2;
        }
      }
    }

    .form.with-form-stepper {
      #adf-form-renderer {
        margin-left: 8px;
        margin-right: 8px;
      }
    }

    eben-form-help {
      position: absolute;
      inset: 0;
      overflow: auto;
      background-color: white;
      z-index: 3;
    }

    @media ($media-breakpoint-max-sm) {
      eben-form-stepper {
        display: none;
      }

      eben-form-help {
      }

      .form.with-form-stepper {
        margin-left: 0;
      }

      .form {
        margin-left: 0;

        #adf-form-renderer {
          margin-left: 8px;
          margin-right: 8px ;
        }

        .left-drawer-toggle, .right-drawer-toggle {
          display: none;
        }
      }
    }
  }
}
