shared-ui-process-audit {
  mat-accordion {
    mat-expansion-panel {
      .mat-expansion-panel-header-title,
      .mat-expansion-panel-header-description {
        flex-basis: 0;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
