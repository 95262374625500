#field-vorteilscard-container,
#field-schlafwagen-container,
#field-airplus-container,
#field-pkw_begrundung-container,
#field-mitfahrt_bei-container,
#field-extern_ubernommen_kommentar-container,
#field-vorschussBetrag-container,
#field-flug_begrundung-container {
  margin-left: 16px;
  animation-duration: 0.5s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 0;
    opacity: 0;
  }

  to {
    margin-left: 16px;
    opacity: 1;
  }
}
