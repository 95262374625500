@import "../vars";

table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr {

      border-bottom: 1pt solid $grey_lighter;

      th {
        padding: 8px;
        text-align: left;
        font-size: $font_smaller;
        color: $grey-darker;
        font-weight: 400;
      }
    }
  }

  tbody {

    tr {

      border-bottom: 1pt solid $grey_lighter;

      td {
        padding: 8px;
      }
    }

  }
}
