@import "../vars";

.mat-sidenav-container.mat-drawer-container {
  min-height: calc(100vh - 64px);
  background-color: $grey_lighter;

  .mat-sidenav {
    width: 400px;
    max-width: 100vw;

    .sidenav-area {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $grey_lighter;
      text-align: left;

      .active {
        background-color: $grey_lighter;
      }
    }
  }
}
