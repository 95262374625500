/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import 'styles/adf-styles';
@import 'styles/spacing-helper';

@import 'styles/forms/reise-auf';
@import 'styles/forms/boku-homeoffice-grunddaten';
@import 'styles/vars';

@import "styles/boku-theme";
@import 'styles/components/input';
@import 'styles/components/tooltip';
@import 'styles/components/list';
@import 'styles/components/mat-datepicker';
@import 'styles/components/mat-button';
@import 'styles/components/mat-toolbar';
@import 'styles/components/mat-sidenav';
@import 'styles/components/eben-widget';
@import 'styles/components/eben-table';
@import 'styles/components/eben-dialog';
@import 'styles/components/shared-ui-process-diagram';
@import 'styles/components/shared-ui-process-audit';
@import 'styles/components/eben-ui-avatar-fullname';
@import 'styles/components/eben-ui-dialog-process-information';
@import 'styles/components/property-facet-field-filter';
@import 'styles/components/chip-list';

$theme: mat.define-light-theme($primary, $accent, $warn);
@include mat.all-component-themes($theme);

$flow-typography: mat.define-typography-config(
  $font-family: 'Roboto, monospace',
  $display-4: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $display-3: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $display-2: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $display-1: mat.define-typography-level(34px, 40px, 400),
  $headline: mat.define-typography-level(24px, 32px, 400),
  $title: mat.define-typography-level(20px, 32px, 500),
  $subheading-2: mat.define-typography-level(16px, 28px, 400),
  $subheading-1: mat.define-typography-level(15px, 24px, 400),
  $body-2: mat.define-typography-level(14px, 24px, 500),
  $body-1: mat.define-typography-level(14px, 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
  $input: mat.define-typography-level(inherit, 1.125, 400)
);
@include mat.core($flow-typography);
$config: mat.define-typography-config();

body, html {
  @include mat.typography-level($config, body-1);
  background-color: $grey-lighter;
  margin: 0;
}

.mat-caption {
  @include mat.typography-level($config, caption);
  color: $grey_darker;
}

.flow-logo {
  .icon {
    cursor: pointer;
    width: 100px !important;
    height: 40px !important;
  }
}

.flex-container {
  display: flex;

  &.flex-container__gap-4 {
    gap: 4px;
  }

  &.center-align {
    align-items: center;
  }
}

.spacer {
  flex: 1 1 auto;
}

.error-text {
  color: red;
}

a {
  text-decoration:none;
  &:focus, &:hover, &:active {
    text-decoration: underline;
  }
}

b {
  font-weight: 500;
}

// ********************************************
// SCROLLBAR
// ********************************************

*,
*:focus,
*:hover {
  outline: none;
}

// ********************************************
// SNACKBAR STYLES
// ********************************************

snack-bar-container.mat-snack-bar-container {
  &.snackbar-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &.snackbar-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
}

// ********************************************
// DIALOG STYLES
// ********************************************

.dialog-container {
  background-color: white;
  border: 1px solid $grey-lighter;
  padding: 8px;

  .dialog-actions {
    text-align: right;
  }
}
