@use '@angular/material' as mat;
@import "../_vars.scss";

.small-elevation-dialog {

  mat-dialog-container {
    @include mat.elevation(1, 000);
    transform: none;
    padding: 0;
    overflow: hidden;

    @media screen and ($media-breakpoint-max-sm) {
      position: absolute;
      inset: 0;
    }

    mat-dialog-content {
      max-height: 100vh;
    }
  }
}

shared-ui-property-facet-field-default-filter,
shared-ui-property-facet-field-date-filter {
  button.dialog-button {
    height: 100%;
    font-weight: 400;

    span.mat-button-wrapper {
      padding-right: 12px;
    }

    &:before {
      font-family: "Material Icons";
      content: "expand_more";
      font-size: 20px;
      position: absolute;
      right: 4px;
      pointer-events: none;
      color: #bdbdbd;
    }

    &.highlight {
      background-color: mat.get-color-from-palette(mat.$blue-palette, 200);

      &:before {
        color: mat.get-color-from-palette(mat.$blue-palette, 600);
      }
    }
  }
}

shared-ui-property-facet-field-default-filter {
  button.facet-field-button {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 250px;
  }
}
