@import "apps/flow/src/styles/vars.scss";

input + mat-chip-list {
  margin-top: 4px;
}

mat-chip-list.mat-chip-list__block {
  .mat-standard-chip {
    min-height: 38px;
  }

  .mat-chip-list-wrapper {
    margin: 4px 0 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: normal;
    gap: 4px;

    .mat-standard-chip {
      margin: 0;

      .mat-chip-avatar {
        margin-left: 8px;
      }

      .mat-chip-remove.mat-icon {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
      }

      span {
        flex-grow: 1;
      }
    }
  }

  .mat-chip {
    font-weight: normal;
    background-color: $grey_lighter;
    border-radius: 4px;
    color: #495057;
  }
}
