@import "../vars";

.boku-input-theme-focus {
  color: #495057;
  background-color: #fff;
  border-color: #92e072;
  outline: 0;
  box-shadow: 0 0 2px 0.2rem rgb(77 172 38 / 25%);
}

input {

  display: block;
  width: 100%;
  height: 42px;
  padding-left: 0.75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
  font: 400 14px/20px Roboto, monospace;

  &:focus {
    @extend .boku-input-theme-focus
  }
}

.adaptive-select-autocomplete {

  position: relative;
  display: flex;
  cursor: pointer;

  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  height: 38px;

  &:focus-within {
    @extend .boku-input-theme-focus
  }

  & .autocomplete-container {
    width: 100%;
    button.mat-button-base.mat-flat-button {
      width: 100%;
      height: 38px;
      background-color: transparent;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      padding-left: 0.75em;

      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 60px;

      color: rgba(0, 0, 0, 0.87);

      .mat-button-focus-overlay {
        background-color: transparent !important;
      }
    }
  }

  & .select-container {

    width: 100%;

    & mat-select {

      height: 38px;
      line-height: 38px;

      .mat-select-value {
        padding-left: 0.75rem;
        padding-right: 60px;
      }

      .mat-select-arrow-wrapper .mat-select-arrow {
        border: none;
        margin: unset;
      }
    }
  }

  button.mat-icon-button {
    position: absolute;
    width: 38px;
    height: 38px;
    line-height: 38px;
    right: 24px;
    color: $grey_darker;
  }

  mat-icon.expand_more {
    position: absolute;
    line-height: 38px;
    height: 38px;
    width: 24px;
    right: 4px;
    color: $grey_darker;
  }
}

select {

  margin-right: 24px;

  @extend input;
  font-size: 1rem;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  // ms fix
  &::-ms-expand {
    display: none;
  }

  :focus::-ms-value {
    background-color: transparent;
  }

  // chrome fix
  outline: none;
  padding-left: 0.75rem;

  -moz-padding-start: 0.75rem;
  //background-color: #fff;
  //border: none;
  //width: 100%;
  //height: 100%;
  //color: $black;

  &.default {
    color: $grey_dark;
  }

  & option {
    color: $grey_darker;
  }
}

textarea {
  @extend input;
  height: 4em;
  padding: 8px;
}
