@use '@angular/material' as mat;
@import 'apps/flow/src/styles/vars';

mat-toolbar {
  &.mat-toolbar {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 2;
    gap: 8px;
    @include mat.elevation(1, $grey);
  }
}
