@use '@angular/material' as mat;
@import "./boku-theme";
@import "./vars";


adf-form-renderer {
  & .adf-container-widget {
    & section.adf-grid-list-column-view {
      margin-right: 0;
      & .adf-grid-list-single-column {

        & .adf-grid-list-column-view-item {
          padding-left: 0;
          padding-right: 0;
        }
      }

      @media ($media-breakpoint-max-md) {
        flex-direction: column;
        margin-right: 0;
      }
    }



    /**
      container header
     */
    .adf-container-widget__header-text {
      font-size: 16px;
      font-weight: 400;
      margin-top: 8px;


      h4 {
        border-bottom: unset;
      }

      border-bottom: 1px solid mat.get-color-from-palette($primary, 0.3, 0.3);
    }
  }
}
