.padding-0 {
  padding: 0px !important;
}

.padding-left-0 {
  padding-left: 0px !important;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.padding-right-0 {
  padding-right: 0px !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.padding-vertical-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.padding-horizontal-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.padding-4 {
  padding: 4px !important;
}

.padding-left-4 {
  padding-left: 4px !important;
}

.padding-top-4 {
  padding-top: 4px !important;
}

.padding-right-4 {
  padding-right: 4px !important;
}

.padding-bottom-4 {
  padding-bottom: 4px !important;
}

.padding-vertical-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.padding-horizontal-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.padding-8 {
  padding: 8px !important;
}

.padding-left-8 {
  padding-left: 8px !important;
}

.padding-top-8 {
  padding-top: 8px !important;
}

.padding-right-8 {
  padding-right: 8px !important;
}

.padding-bottom-8 {
  padding-bottom: 8px !important;
}

.padding-vertical-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.padding-horizontal-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.padding-16 {
  padding: 16px !important;
}

.padding-left-16 {
  padding-left: 16px !important;
}

.padding-top-16 {
  padding-top: 16px !important;
}

.padding-right-16 {
  padding-right: 16px !important;
}

.padding-bottom-16 {
  padding-bottom: 16px !important;
}

.padding-vertical-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.padding-horizontal-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.padding-24 {
  padding: 24px !important;
}

.padding-left-24 {
  padding-left: 24px !important;
}

.padding-top-24 {
  padding-top: 24px !important;
}

.padding-right-24 {
  padding-right: 24px !important;
}

.padding-bottom-24 {
  padding-bottom: 24px !important;
}

.padding-vertical-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.padding-horizontal-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.padding-32 {
  padding: 32px !important;
}

.padding-left-32 {
  padding-left: 32px !important;
}

.padding-top-32 {
  padding-top: 32px !important;
}

.padding-right-32 {
  padding-right: 32px !important;
}

.padding-bottom-32 {
  padding-bottom: 32px !important;
}

.padding-vertical-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.padding-horizontal-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.padding-40 {
  padding: 40px !important;
}

.padding-left-40 {
  padding-left: 40px !important;
}

.padding-top-40 {
  padding-top: 40px !important;
}

.padding-right-40 {
  padding-right: 40px !important;
}

.padding-bottom-40 {
  padding-bottom: 40px !important;
}

.padding-vertical-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.padding-horizontal-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.margin-0 {
  margin: 0px !important;
}

.margin-left-0 {
  margin-left: 0px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-right-0 {
  margin-right: 0px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-vertical-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.margin-horizontal-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.margin-4 {
  margin: 4px !important;
}

.margin-left-4 {
  margin-left: 4px !important;
}

.margin-top-4 {
  margin-top: 4px !important;
}

.margin-right-4 {
  margin-right: 4px !important;
}

.margin-bottom-4 {
  margin-bottom: 4px !important;
}

.margin-vertical-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.margin-horizontal-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.margin-8 {
  margin: 8px !important;
}

.margin-left-8 {
  margin-left: 8px !important;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-right-8 {
  margin-right: 8px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.margin-vertical-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.margin-horizontal-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.margin-16 {
  margin: 16px !important;
}

.margin-left-16 {
  margin-left: 16px !important;
}

.margin-top-16 {
  margin-top: 16px !important;
}

.margin-right-16 {
  margin-right: 16px !important;
}

.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.margin-vertical-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.margin-horizontal-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.margin-24 {
  margin: 24px !important;
}

.margin-left-24 {
  margin-left: 24px !important;
}

.margin-top-24 {
  margin-top: 24px !important;
}

.margin-right-24 {
  margin-right: 24px !important;
}

.margin-bottom-24 {
  margin-bottom: 24px !important;
}

.margin-vertical-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.margin-horizontal-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.margin-32 {
  margin: 32px !important;
}

.margin-left-32 {
  margin-left: 32px !important;
}

.margin-top-32 {
  margin-top: 32px !important;
}

.margin-right-32 {
  margin-right: 32px !important;
}

.margin-bottom-32 {
  margin-bottom: 32px !important;
}

.margin-vertical-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.margin-horizontal-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.margin-40 {
  margin: 40px !important;
}

.margin-left-40 {
  margin-left: 40px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-right-40 {
  margin-right: 40px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-vertical-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.margin-horizontal-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.margin-left-64 {
  margin-left: 64px !important;
}
