@use '@angular/material' as mat;
@import 'node_modules/@angular/material/theming';

$grey_lighter: mat.get-color-from-palette(mat.$grey-palette, 200);
$grey_light: mat.get-color-from-palette(mat.$grey-palette, 300);
$grey: mat.get-color-from-palette(mat.$grey-palette, 400);
$grey_dark: mat.get-color-from-palette(mat.$grey-palette, 500);
$grey_darker: mat.get-color-from-palette(mat.$grey-palette, 600);

$font_smaller: 12px;
$font_small: 14px;
$font_normal: 15px;
$font_big: 16px;
$font_bigger: 18px;
$font_biggest: 20px;

$media-breakpoint-max-xs: 'max-width: 575.99px';
$media-breakpoint-min-sm: 'min-width: 576px';
$media-breakpoint-max-sm: 'max-width: 767.99px';
$media-breakpoint-min-md: 'min-width: 768px';
$media-breakpoint-max-md: 'max-width: 991.99px';
$media-breakpoint-min-lg: 'min-width: 992px';
$media-breakpoint-max-lg: 'max-width: 1199.99px';
$media-breakpoint-min-xl: 'min-width: 1200px';
$media-breakpoint-max-xl: 'max-width: 1399.99px';
$media-breakpoint-min-xxl: 'min-width: 1400px';
